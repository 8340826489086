Ycc.DatePicker = (function(el) {
  var self = this;

  self.init = function(el) {
    self.el = el;
    var value = self.el.val();
    // OH中的DatePicker使用用户当地时间, 为了不影响其他地方，这里加个timezone参数
    // 如果el data中指定了timezone, 则使用moment使用指定的timezone
    if (self.el.data('timezone')) moment.tz.setDefault(self.el.data('timezone'));
    self.excludeTime = self.el.data('exclude-time') || false;
    if (self.el.data('min-date-interval-unit')) {
      self.minDateInterval = moment(value).add(parseInt(self.el.data('min-date-interval-value')), self.el.data('min-date-interval-unit'));
    }
    if (self.el.data('max-date-interval-unit')) {
      self.maxDateInterval = moment(value).add(parseInt(self.el.data('max-date-interval-value')), self.el.data('max-date-interval-unit'));
    }
    if (self.el.data('time-increment')) {
      self.timePickerIncrement = parseInt(self.el.data('time-increment'));
    }
    self.singleDatePicker = self.el.data('single-date-picker') === false ? false : true;
    self.allowBlank = self.el.data('allow-blank');
    self.autoUpdateInput = self.allowBlank ? false : true;
    self.render();
  };

  self.render = function() {
    var value = self.el.val();
    var options;
    if (self.excludeTime) {
      options = {
        autoApply: true,
        autoUpdateInput: self.autoUpdateInput,
        singleDatePicker: self.singleDatePicker,
        timePicker: false,
        locale: {
          format: 'YYYY-MM-DD'
        }
      }
      if (self.minDateInterval || value) {
        options.minDate = self.minDateInterval || moment(value).subtract(4, 'year')
      }
      if (self.maxDateInterval || value) {
        options.maxDate = self.maxDateInterval || moment(value).add(12, 'months')
      }
    } else {
      options = {
        autoApply: true,
        autoUpdateInput: self.autoUpdateInput,
        singleDatePicker: self.singleDatePicker,
        minDate: self.minDateInterval ||  (value ? moment() : moment().add(1, 'hour')).startOf('hour'),
        maxDate: self.maxDateInterval ||  moment(value).add(12, 'months').startOf('hour'),
        timePicker: true,
        timePickerIncrement: self.timePickerIncrement || 30,
        locale: {
          format: 'YYYY-MM-DD h:mm A'
        }
      }
    }
    options.locale = $.extend(options.locale, {
      applyLabel: Ycc.locale_settings.apply,
      cancelLabel: Ycc.locale_settings.cancel,
      toLabel: Ycc.locale_settings.to,
      daysOfWeek: Ycc.locale_settings.abbr_day_names,
      monthNames: Ycc.locale_settings.month_names
    });
    var dateRangePicker;
    if (self.allowBlank) {
      dateRangePicker = self.el.daterangepicker(options).on('apply.daterangepicker', function(ev, picker) {
        self.el.val(picker.startDate.format('YYYY-MM-DD'))
      })
    } else {
      dateRangePicker = self.el.daterangepicker(options);
    }
    if (value) {
      if (!value.includes(' - ')) {
        self.el.data('daterangepicker').setStartDate(moment(value));
        self.el.data('daterangepicker').setEndDate(moment(value));
      }
    } else {
      self.el.val('');
      self.el.attr('placeholder', self.el.data('placeholder'));
    }
    self.renderClearButton();
  };

  self.renderClearButton = function() {
    self.el.parent().find('[data-role=clear_date_picker_button]').click(function() {
      self.el.val('');
      $(this).closest('form').submit();
      return false;
    });
  };

  self.init(el);

  return self;
});
